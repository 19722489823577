<template>
    <v-dialog
        :key="shipmentId"
        max-width="700px !important"
        overlay=false
        persistent
        scrollable
        transition="dialog-bottom-transition"
        v-model="open"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.shippingInfo') + ": " + mainDocument }}</v-card-title>
            <v-card-text class="pa-3 overflow-y-auto">
                <v-card class="px-3 py-2">
                    <v-overlay
                        :value="loading.info"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.buyer') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.Customer.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.supplier') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.Supplier.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.paymentTerms') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Paymentstatement.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.portOfLoading') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Originport.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.countryOfLoading') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Origin.name }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.destinationPort') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Destinationport.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.destinationCountry') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Destination.name }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.osd') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.shipweekdate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.etd') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.etddate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.eta') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.etadate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.sailingConfirmed') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0">
                            <span class="text--black" v-if="shippingInfo.Shipment.sailing == 1">{{ $t('message.yes') }}</span>
                            <span class="text--black" v-else>{{ $t('message.no') }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.shipmentStatus') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0">
                            <span class="text--black" v-if="shippingInfo.Shipment.shipmentstatus_id == 6">{{ shippingInfo.Shipment.shipmentstatus_text }}</span>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.blNo') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.blno }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.containerNos') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0">
                            <div class="text--black" v-for="(container,index) in containers" :key="index">{{ container.Container.title }}</div>
                        </v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.carrier') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Agent.title }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.documentsCourierAwb') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ shippingInfo.Shipment.courier }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.documentsSentOn') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black">{{ formatDate(shippingInfo.Shipment.courierdate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.containerDelivered') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black" v-if="shippingInfo.Shipment.hascontainerdate == 1 && containerDate > etaDate ">{{ formatDate(shippingInfo.Shipment.containerdate) }}</span></v-col>
                    </v-row>
                    <v-row class="py-0" v-if="false">
                        <v-col cols="5" md="5" lg="5" class="py-0 font-weight-bold">{{ $t('message.emptyContainerReturned') }}</v-col>
                        <v-col cols="7" md="7" lg="7" class="py-0"><span class="text--black"></span></v-col>
                    </v-row>
                </v-card>
                <v-card class="mt-3 px-3 py-2">
                    <v-overlay
                        :value="loading.updates"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :headers="shippingUpdatesHeaders"
                        :items="shippingUpdates"
                        calculate-widths
                        dense
                        disable-sort
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:top>
                            <div class="font-weight-bold">{{ $t('message.appicShipmentUpdatesOnly') }}</div>
                        </template>
                        <template v-slot:item.ShipmentUpdate.date_created="{ item }">
                            <span class="text-no-wrap">{{ formatDate(item.ShipmentUpdate.date_created) + " " + item.ShipmentUpdate.time_created }}</span>
                        </template>
                        <template v-slot:item.ShipmentUpdate.update="{ item }">
                            <div v-for="update in item.ShipmentUpdate.update.split(',')">{{ update }}</div>
                        </template>
                        <template v-slot:item.ShipmentUpdate.value="{ item }">
                            <span v-if="item.ShipmentUpdate.value != null">{{ item.ShipmentUpdate.value }}</span>
                            <span v-else>{{ $t('message.na') }}</span>
                        </template>
                    </v-data-table>
                </v-card>
                <v-card class="mt-3 px-3 py-2">
                    <v-overlay
                        :value="loading.notifications"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                        :headers="shippingNotificationsHeaders"
                        :items="shippingNotifications"
                        calculate-widths
                        dense
                        disable-sort
                        disable-pagination
                        hide-default-footer
                    >
                        <template v-slot:top>
                            <div class="font-weight-bold">{{ $t('message.appicShipmentNotificationsOnly') }}</div>
                        </template>
                        <template v-slot:item.Notification.senddate="{ item }">
                            <span class="text-no-wrap">{{ formatDate(item.Notification.senddate.split(' ')[0]) + " " + item.Notification.senddate.split(' ')[1] }}</span>
                        </template>
                        <template v-slot:item.Notification.message="{ item }">
                            <div v-for="message in item.Notification.message.split(',')">{{ message }}</div>
                        </template>
                    </v-data-table>
                </v-card>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ml-3"
                    color="default"
                    small
                    @click="open = false"
                >
                    {{ $t('message.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {formatDate, numberFormat} from "Helpers/helpers";
import {api} from "Api";

export default {
    name: "ShippingInfoSummary",
    props: {
        shipmentId: {
            type: Number,
        },
        mainDocument: {
            type: String,
        },
        openDialog: {
            type: Boolean,
        }
    },
    data(){
        return {
            open: false,
            containers: [],
            loading: {
                info: false,
                notifications: false,
                updates: false
            },
            shippingInfo: {
                Agent: {},
                Destination: {},
                Destinationport: {},
                Origin: {},
                Originport: {},
                Paymentstatement: {},
                Shipment: {
                    Customer: {},
                    Supplier: {},
                },

            },
            shippingNotifications: [],
            shippingNotificationsHeaders: [
                {
                    id: 0,
                    text: this.$t('message.dateSent'),
                    value: 'Notification.senddate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 1,
                    text: this.$t('message.recipients'),
                    value: 'Notification.recipients',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.message'),
                    value: 'Notification.message',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 3,
                    text: this.$t('message.sender'),
                    value: 'Notification.sender',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
            ],
            shippingUpdates: [],
            shippingUpdatesHeaders: [
                {
                    id: 0,
                    text: this.$t('message.date'),
                    value: 'ShipmentUpdate.date_created',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 1,
                    text: this.$t('message.update'),
                    value: 'ShipmentUpdate.update',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.value'),
                    value: 'ShipmentUpdate.value',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                },
                {
                    id: 3,
                    text: this.$t('message.user'),
                    value: 'ShipmentUpdate.user',
                    class: 'light-green lighten-3 pa-1',
                    sortable: false
                }
            ]
        }
    },
    computed: {
        containerDate(){
            return new Date(this.shippingInfo.Shipment.containerdate)
        },
        etaDate(){
            return new Date(this.shippingInfo.Shipment.etadate)
        }
    },
    methods: {
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        loadContainers(){
            return new Promise((resolve, reject) => {
                api
                    .get("/contracts/" + this.shipmentId + '/containers')
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadShippingInfo(){
            return new Promise((resolve, reject) => {
                api
                    .get("/shipments/" + this.shipmentId)
                    .then(response => {
                        resolve(response.data.data[0])
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadShippingNotifications(){
            return new Promise((resolve, reject) => {
                api
                    .get("/notifications/shipments/" + this.shipmentId)
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        loadShippingUpdates(){
            return new Promise((resolve, reject) => {
                api
                    .get("/shipments/" + this.shipmentId + "/updates")
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        }
    },
    watch: {
        open (value) {
            if(value == false){
                this.$emit('dialog-closed')
            }
        },
        openDialog (value) {
            if(value){
                this.open = value
                this.loading.info = true
                this.loading.notifications = true
                this.loading.updates = true
                this.loadShippingInfo()
                    .then(response => {
                        this.shippingInfo = response
                        this.loadContainers()
                            .then(response => {
                                this.containers = response
                                this.loadShippingUpdates()
                                    .then(response => {
                                        this.shippingUpdates = response
                                        this.loadShippingNotifications()
                                            .then((response)=>{
                                                this.shippingNotifications = response
                                                this.loading.info = false
                                                this.loading.notifications = false
                                                this.loading.updates = false
                                            })
                                    })
                            })
                    })
            }
        }
    }
}
</script>

<style>
.text--black {
    color: black !important;
}

.v-dialog {
    min-width: 500px !important;
    max-width: 700px !important;
}
</style>